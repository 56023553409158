import axios from "axios";
import websiteURL from "@/websiteURL";

export async function fetchCategorie(perPage, curentPage) {
  const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/categories";
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.get(apiUrl, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
      params: {
        parent: 0,
        per_page: perPage,
        page: curentPage,
      },
    });
    const categories = response.data;
    const totalCategories = response.headers["x-wp-total"];
    const categorieInfo = { categories, totalCategories };
    for (var i = 0; i < categories.length; i++) {
      categories[i].showDetails = false;
    }

    return categorieInfo;
  } catch (error) {
    // Handle errors, e.g., show an error message
    console.error("Error fetching subcategories:", error);
    //this.items = [];
  }
}
