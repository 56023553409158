<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3">Modification Categorie</h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxEdit()"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Information categorie</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <H5 class="form-label">Nom </H5>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom categorie"
                  v-model="categoryInfo.name"
                />
                <label
                  >Le nom est la façon dont il apparaît sur votre site.</label
                >
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <H5 class="form-label">Slug </H5>
                  </div>

                  <input
                    class="form-control"
                    placeholder="SLUG"
                    v-model="categoryInfo.slug"
                  />

                  <label
                    >Le « slug » est la version du nom normalisée pour les
                    URL.</label
                  >
                </div>
                <div class="col">
                  <div class="card-header" style="padding: 0.5rem">
                    <h5 class="form-label">Catégorie Parent</h5>
                    <feather-icon
                      style="color: #19b4bf; cursor: pointer"
                      icon="RefreshCcwIcon"
                      size="20"
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      v-b-tooltip.hover.v-dark
                      title="Refresh category list"
                      variant="gradient-primary"
                    />
                  </div>
                  <v-select
                    :disabled="categoryInfo.parent === 0"
                    style="cursor: pointer"
                    :loading="categoriesLoader"
                    :options="listCategories.categories"
                    label="name"
                    :reduce="(categoryInfo) => categoryInfo.id"
                    v-model="categoryInfo.parent"
                  />
                  <label
                    >Assignez un terme parent pour créer une hiérarchie.</label
                  >
                </div>
              </div>

              <!-- Description -->
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="categoryInfo.description" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-12 col-lg-1">
                  <div>
                    <feather-icon
                      style="color: #19b4bf; cursor: pointer"
                      icon="InfoIcon"
                      size="20"
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-11">
                  <label style="font-weight: bold">
                    Pour modifier une image, veuillez supprimer l'image
                    existante puis re-uploader une nouvelle, merci
                  </label>
                </div>
              </div>
              <div class="row mb-3">
                <b-overlay
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :max-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    v-model="imageCategory"
                    :data-images="imageCategory"
                    @before-remove="beforeRemove"
                    @upload-success="uploadImageAndReturnSrc"
                    :showEdit="false"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { uploadImageSuccess } from "../../views/Products/ProductSettings/FileUpload.js";
import axios from "axios";
import websiteURL from "@/websiteURL";
import Ripple from "vue-ripple-directive";
import { BOverlay, VBTooltip } from "bootstrap-vue";
import { fetchCategorie } from "../../views/Categories/CategoryFunction.js";
import vSelect from "vue-select";
export default {
  components: {
    quillEditor,
    VueUploadMultipleImage,
    BOverlay,
    vSelect,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      categoriesLoader: false,
      listCategories: [],
      categoryInfo: [],
      boxMsgEdit: "",
      imageCategory: [],
      image: {
        src: "",
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl =
      websiteURL.WEBSITE + `wp-json/wc/v3/products/categories/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.categoryInfo = response.data;
        if (vm.categoryInfo.image) {
          vm.imageCategory = vm.transformImagesUploadToImagesArray(
            vm.categoryInfo.image
          );
        }
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    async uploadImageAndReturnSrc(formData, index, fileList) {
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        this.imageCategory = this.transformImagesUploadToImagesArray(
          uploadedUrls[0]
        );
      } catch (error) {
        alert("Une erreur s'est produite lors de l'upload de l'image", error);
      }
    },
    transformImagesUploadToImagesArray(imagesUploadArray) {
      const transformedArray = [];

      transformedArray.push({
        name: "",
        path: imagesUploadArray.src,
        highlight: 1,
        default: 1,
      });
      return transformedArray;
    },
    async editCategory() {
      if (this.imageCategory.length > 0) {
        this.image.src = this.imageCategory[0].path;
      }
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/categories/${this.categoryInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          name: this.categoryInfo.name,
          description: this.categoryInfo.description,
          slug: this.categoryInfo.slug,
          parent: this.categoryInfo.parent,
          image: this.image,
        };

        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.editConfirmeToastMsg("info");
      } catch (error) {
        alert(
          "Une erreur s'est produite lors de la modification de la catégorie"
        );
      }
    },
    showMsgBoxEdit() {
      this.boxMsgEdit = "";
      this.$bvModal
        .msgBoxConfirm(
          "Êtes-vous sûr de vouloir mettre à jour la catégorie ?",
          {
            title: "Confirmation",
            size: "sm",
            okVariant: "primary",
            okTitle: this.loading ? "Loading..." : "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          this.boxMsgEdit = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.editCategory().then(() => {
                this.loading = false;
              });
            }
          }
        });
    },
    editConfirmeToastMsg(variant = null) {
      this.$bvToast.toast("La catégorie a bien été mise à jour", {
        title: `Catégorie ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Voulez vous vraiment supprimer l'image ?");
      if (r == true) {
        this.imageCategory = [];
        done();
      } else {
      }
    },
  },
  async created() {
    this.listCategories = await fetchCategorie();
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 150px;
    }
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
